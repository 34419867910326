export const Status = Object.freeze({
  DISABLED: "DISABLED",
  ACTIVE: "ACTIVE",
  BLOCKED: "BLOCKED",
  ARCHIVED: "ARCHIVED",
});

export const PriceDriveType = Object.freeze({
  FIXED: "FIXED",
  DISTANCE: "DISTANCE",
});

export const PriceStartFareType = Object.freeze({
  FIXED: "FIXED", // Použiť defaultné hodnoty prístavného (1)
  FROM_STAND: "FROM_STAND", // PRÍSTAVNÉ obsahuje jazdu zo Stanovišťa, ostatné sú defaultné hodnoty (2)
  TO_STAND: "TO_STAND", // PRÍSTAVNÉ obsahuje jazdu na Stanovište, ostatné sú defaultné hodnoty (3)
  BETWEEN_STAND: "BETWEEN_STAND", // PRÍSTAVNÉ obsahuje jazdu z aj na Stanovište, ostatné sú defaultné hodnoty (4)
});

export const Service = Object.freeze({
  PERSONAL: "PERSONAL",
  LIMO: "LIMO",
  CARGO: "CARGO",
  DRINK: "DRINK",
  IMMOBILE: "IMMOBILE",
  COURIER: "COURIER",
  DELIVERY: "DELIVERY",
  AIRPORT: "AIRPORT",
  SOCIAL: "SOCIAL",
});

export const Type = Object.freeze({
  DISABLED: "DISABLED",
  FULL: "FULL",
  DIRECT: "DIRECT",
  DRINK: "DRINK",
  CALL: "CALL",
  SMS: "SMS",
});

export const AutoSendType = Object.freeze({
  DISTANCE: "DISTANCE",
  UNOCCUPIED: "UNOCCUPIED",
  RADIUS: "RADIUS",
  NEAR: "NEAR",
  PRIORITY: "PRIORITY",
});

export const DirectSendType = Object.freeze({
  DISTANCE: "DISTANCE",
  UNOCCUPIED: "UNOCCUPIED",
});

export const Chat = Object.freeze({
  NONE: "NONE", // Bez chatovej komunikácie (0)
  ALL: "ALL",// Chatovacia komunikácia medzi Dispečingom, Vodičom a Cestujúcim (1)
  DIRECT: "DIRECT",	// Chatovacia komunikácia medzi Vodičom a Cestujúcim (2)
  BACK: "BACK",// Chatovacia komunikácia medzi Dispečingom a Cestujúcim (3)
  FRONT: "FRONT",// Chatovacia komunikácia medzi Dispečingom a Vodičom (4)
  SYSTEM: "SYSTEM",// Chatové informácie zo Systému pre Dispečera (5)
});

export const PhoneClient = Object.freeze({
  DISABLED: "DISABLED",
  DRIVER: "DRIVER",
  ANONYMOUS: "ANONYMOUS",
  DISPATCH_01: "DISPATCH_01",
  DISPATCH_02: "DISPATCH_02",
  DISPATCH_03: "DISPATCH_03",
  DISPATCH_04: "DISPATCH_04",
  DISPATCH_05: "DISPATCH_05",
  DISPATCH_06: "DISPATCH_06",
  DISPATCH_07: "DISPATCH_07",
  DISPATCH_08: "DISPATCH_08",
  DISPATCH_09: "DISPATCH_09",
  DISPATCH_10: "DISPATCH_10",
});

export const PhoneDriver = Object.freeze({
  DISABLED: "DISABLED",
  DRIVER: "DRIVER",
  ANONYMOUS: "ANONYMOUS",
  DISPATCH_01: "DISPATCH_01",
  DISPATCH_02: "DISPATCH_02",
  DISPATCH_03: "DISPATCH_03",
  DISPATCH_04: "DISPATCH_04",
  DISPATCH_05: "DISPATCH_05",
  DISPATCH_06: "DISPATCH_06",
  DISPATCH_07: "DISPATCH_07",
  DISPATCH_08: "DISPATCH_08",
  DISPATCH_09: "DISPATCH_09",
  DISPATCH_10: "DISPATCH_10",
});

export const SmsGateway = Object.freeze({
  NONE: "NONE",
  //APP: "APP",
  //PHONE: "PHONE",
  ITWAY: "ITWAY",
  ITWAY2: "ITWAY2",
  VIPTEL: "VIPTEL",
});

export const PhoneGateway = Object.freeze({
  NONE: "NONE",
  //APP: "APP",
  //PHONE: "PHONE",
  ITWAY: "ITWAY",
  ITWAY2: "ITWAY2",
  VIPTEL: "VIPTEL",
});

export const vehicleAllocation = Object.freeze({
  ASSIGNED: "ASSIGNED",
  ALL: "ALL",
});

export const ReceiptEdit = Object.freeze({
  DISABLED: "NONE",
  TYPE: "EXTRA",
  EXTRA: "DRIVE_EXTRA",
  ALL: "DRIVE",
  FULL: "ALL",
});

export const ShowDestination = Object.freeze({
  DISABLED: "DISABLED",
  ALWAYS: "ALWAYS",
  ACCEPTED: "ACCEPTED",
  WAITING: "WAITING",
  IN_PROGRESS: "IN_PROGRESS",
});

export const BreakType = Object.freeze({
  NONE: "NONE",
  BREAK: "BREAK",
  SHIFT: "SHIFT",
  BREAK_AND_SHIFT: "BREAK_AND_SHIFT",
});

export const AcceptSupportOrderType = Object.freeze({
  AUTO: "AUTO",
  MANUAL: "MANUAL",
});

export const SendSupportOrderType = Object.freeze({
  AUTOMAT: "AUTOMAT",
  MANUAL: "MANUAL",
});

export const SendtypeSupportOrderType = Object.freeze({
  NONE: "NONE",
  DIRECT_DRIVER: "DIRECT_DRIVER",
  DIRECT_LOCALITY: "DIRECT_LOCALITY",
  DIRECT_STAND: "DIRECT_STAND",
  DISTANCE: "DISTANCE",
  PRIORITY: "PRIORITY",
  RADIUS: "RADIUS",
  UNOCCUPIED: "UNOCCUPIED",
});

export const TypeSupportOrderType = Object.freeze({
  AIRPORT: "AIRPORT",
  DIRECT: "DIRECT",
  INSTANT: "INSTANT",
  REPEATED: "REPEATED",
  STREET: "STREET",
  TIME: "TIME",
});